import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { Gallery } from "../components/gallery/Gallery"

const CommercialPage = props => {
  const serverData = useStaticQuery(graphql`
    query commercialLayout {
      allContentfulLayout(filter: { name: { eq: "Commercial" } }) {
        nodes {
          data {
            thumbnail {
              fluid {
                src
              }
            }
            contiImages {
              fixed(width: 3000) {
                src
              }
            }
            mobileContiImages {
              fixed(width: 850) {
                src
              }
            }
            images {
              fluid {
                src
              }
            }
            title
          }
          name
        }
      }
    }
  `)

  return (
    <>
      <SEO title="TVCF & M.V 스토리보드" />
      <Gallery contiList={serverData.allContentfulLayout.nodes[0].data} />
    </>
  )
}

export default CommercialPage
